export default [  
  {
    component: 'CNavItem',
    name: 'Inicio',
    to: '/dashboard',
    icon: 'cil-calculator'
  },
  {
    component: 'CNavTitle',
    name: 'Socio',
  },
  {
    component: 'CNavItem',
    name: 'Pedidos',
    to: '/socio/pedidos',
    icon: 'cil-notes',
  },
  {
    component: 'CNavItem',
    name: 'Historial',
    to: '/socio/historial',
    icon: 'cil-calendar',
  },
  {
    component: 'CNavItem',
    name: 'Socio',
    to: '/socio/socio',
    icon: 'cil-user',
  } 
]
