<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false" href="javascript:void(0)">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0"> 
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Cuenta
      </CDropdownHeader>
      <CDropdownItem style="cursor: pointer;" href="#/perfil/perfil"> <CIcon icon="cil-user" /> Perfil </CDropdownItem>
      <!-- <CDropdownItem style="cursor: pointer;" href="#/profile/perfil"> <CIcon icon="cil-settings" /> Configuraciones </CDropdownItem> -->
      <CDropdownItem style="cursor: pointer;" href="#/perfil/cambiarpass"> <CIcon icon="cil-task" /> Cambiar Contraseña  </CDropdownItem>
     
      <CDropdownDivider />     
      <CDropdownItem @click="cerrarSesion()" style="cursor: pointer;"> <CIcon icon="cil-lock-locked" /> Cerrar Sesión </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import { mapActions } from "vuex";
import avatar from '@/assets/images/no_user_photo.png';
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar,
    }
  },
  methods:{
    ...mapActions(['cerrarSesion'])
  }
}
</script>
