export default [  
  {
    component: 'CNavItem',
    name: 'Inicio',
    to: '/dashboard',
    icon: 'cil-calculator',
    // badge: {
    //   color: 'primary',
    //   text: 'NEW',
    // },
  },
  {
    component: 'CNavTitle',
    name: 'Admin',
  },
  {
    component: 'CNavItem',
    name: 'Usuarios',
    to: '/admin/usuarios',
    icon: 'cil-user',
  },
  {
    component: 'CNavItem',
    name: 'Productos',
    to: '/admin/productos',
    icon: 'cil-notes',
  },
  {
    component: 'CNavItem',
    name: 'Configurar Anotes',
    to: '/admin/anotes',
    icon: 'cil-calendar',
  },
  {
    component: 'CNavItem',
    name: 'Pedidos',
    to: '/secretaria/listaPedidos',
    icon: 'cil-calendar',
  },  
  {
    component: 'CNavItem',
    name: 'Socios',
    to: '/secretaria/socios',
    icon: 'cil-user',
  },
  {
    component: 'CNavItem',
    name: 'Reportes',
    to: '/secretaria/reportes',
    icon: 'cil-puzzle',
  },
  {
    component: 'CNavItem',
    name: 'Rechazados',
    to: '/gerencia/pedidos',
    icon: 'cil-calendar',
  },
]