<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <!-- <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <CIcon :icon="logo" height="48" alt="Logo" />
      </CHeaderBrand> -->
      <!-- <CHeaderNav class="d-none d-sm-block ms-auto"> -->
      <CHeaderNav class="ms-auto">
        <CNavItem>
          <CNavLink> {{$store.state.usuarioDB.usuario}} </CNavLink>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>     
        <!-- <AppHeaderDropdownNotif /> -->
        <AppHeaderDropdownAccnt />
      </CHeaderNav>

      <CModal backdrop="static" :visible="cambioModal" @close="() => { cambioModal = false }">
        <CModalHeader>
          <CModalTitle>Cambio </CModalTitle>
        </CModalHeader>
        <CModalBody>
          Cambio
        </CModalBody>
      </CModal>

    </CContainer>
   
  </CHeader>
  
</template>

<script>

import { CNavItem } from '@coreui/vue'
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import AppHeaderDropdownNotif from './AppHeaderDropdownNotif.vue'

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
    AppHeaderDropdownNotif,
    CNavItem
},
  setup() {
    return {
    }
  },
  data(){
    return {
      identificadorTiempoDeEspera:null,
      cambioModal: false
    }
  },
  methods: {
    temporizadorDeRetraso() {
      this.identificadorTiempoDeEspera = setTimeout(this.funcionConRetraso, 3000);
    },
    funcionConRetraso() {
      this.$alert("La sesión finalizará","Atención","warning"); 
    },
    verModal(){
      this.cambioModal = true; 
    }
  },
  created(){
    //this.temporizadorDeRetraso();  
  }, 
  mounted: function() {
    //console.log('mounted');
    //this.verModal();
  }
}
</script>
