export default [  
  {
    component: 'CNavItem',
    name: 'Inicio',
    to: '/dashboard',
    icon: 'cil-calculator'
  },
  {
    component: 'CNavTitle',
    name: 'Gerencia',
  },
  {
    component: 'CNavItem',
    name: 'Pedidos',
    to: '/gerencia/pedidos',
    icon: 'cil-calendar',
  },
  {
    component: 'CNavItem',
    name: 'Reportes',
    to: '/gerencia/reportes',
    icon: 'cil-puzzle',
  },
]
