import { createStore } from 'vuex'
import decode from 'jwt-decode';
import router from '../router/index';

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    token:'',
    usuarioDB: '',
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    obtenerUsuario(state, payload){       
      state.token = payload;
      if(payload === ''){
        state.usuarioDB = '';
      }else{
        state.usuarioDB = decode(payload);
        router.push({name: 'Dashboard'});
      }
    },
  },
  actions: {
    guardarUsuario({commit}, payload){
      localStorage.setItem('token', payload);
      commit('obtenerUsuario', payload)
    },
    cerrarSesion({commit}){
      commit('obtenerUsuario', '');
      localStorage.removeItem('token');
      router.push({name: 'Login'});
    },
    leerToken({commit}){
      const token = localStorage.getItem('token');
      if(token){
        commit('obtenerUsuario', token);
      }else{
        commit('obtenerUsuario', '');
      }
    }
  },
  modules: {},
  getters:{
    estaActivo: state => !!state.token
  }

})
