import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import axios from 'axios';
import VueAxios from 'vue-axios';
import moment from 'moment';
import 'font-awesome/css/font-awesome.min.css';
import '@/assets/css/style.css';

import VueSimpleAlert from "vue3-simple-alert-next";

const app = createApp(App)

moment.locale('es');
app.config.globalProperties.$moment=moment;

app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueSimpleAlert);
app.use(VueAxios, axios);

app.provide('icons', icons)
app.component('CIcon', CIcon)

axios.defaults.baseURL = process.env.VUE_APP_URL;

axios.interceptors.response.use((response) => {
    if(response.status == 401 || response.status == 403) {
      localStorage.removeItem('token');
      window.location.href = `${process.env.VUE_APP_BASE_URL}`;
    }
    return response;
  }, (error) => {
    if(error.response){
      if(error.response.status == 401 || error.response.status == 403) {
        localStorage.removeItem('token');
        window.location.href = `${process.env.VUE_APP_BASE_URL}`;
      }
    }
});
  
axios.interceptors.request.use(
    config => {
        if (localStorage.getItem("token")) {       
            config.headers.token = localStorage.getItem("token");
        }
        return config;
    },
    function(err) {
        console.log("No hay token desde el interceptor" + err);
    }
);
  
app.mount('#app')
