import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store/index';
import decode from 'jwt-decode';
import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    meta: { requireAuth: true},
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: '/perfil',
        name: 'Perfil',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/dashboard',
        children: [
          {
            path: '/perfil/perfil',
            name: 'Perfil',
            component: () => import('@/views/perfil/Perfil.vue'),
          },
          {
            path: '/perfil/cambiarPass',
            name: 'Cambiar Password',
            component: () => import('@/views/perfil/CambiarPass.vue'),
          }
        ],
      },
      {
        path: '/admin',
        name: 'Admin',
        meta: {adminAuth:true},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/dashboard',
        children: [
          {
            path: '/admin/usuarios',
            name: 'Usuarios',
            component: () => import('@/views/admin/Usuarios.vue'),
          },
          {
            path: '/admin/productos',
            name: 'Productos',
            component: () => import('@/views/admin/Productos.vue'),
          },
          {
            path: '/admin/anotes',
            name: 'GestionAnotes',
            component: () => import('@/views/admin/GestionAnotes.vue'),
          }, 
          {
            path: '/secretaria/socios',
            name: 'Socios',
            component: () => import('@/views/secretaria/Socios.vue'),
          },         
          {
            path: '/secretaria/soportePedidos/:id',
            name: 'SoportePedidos',
            component: () => import('@/views/secretaria/SoportePedidos.vue'),
          },
          {
            path: '/gerencia/pedidos',
            name: 'GerenciaPedidos',
            component: () => import('@/views/gerencia/Pedidos.vue'),
          },
          {
            path: '/secretaria/reportes',
            name: 'Reportes',
            component: () => import('@/views/secretaria/Reportes.vue'),
          },
          {
            path: '/secretaria/historial',
            name: 'HistorialSocio',
            component: () => import('@/views/secretaria/HistorialSocio.vue'),
          },
        ],
      },
      {
        path: '/secretaria',
        name: 'Secretaria',
        meta: { secretariaAuth:true},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/dashboard',
        children: [          
          {
            path: '/secretaria/listaPedidos',
            name: 'ListarPedidos',
            component: () => import('@/views/secretaria/ListaPedidos.vue'),
          },
          {
            path: '/secretaria/reportes',
            name: 'Reportes',
            component: () => import('@/views/secretaria/Reportes.vue'),
          },
          {
            path: '/secretaria/socios',
            name: 'Socios',
            component: () => import('@/views/secretaria/Socios.vue'),
          },         
          {
            path: '/secretaria/soportePedidos/:id',
            name: 'SoportePedidos',
            component: () => import('@/views/secretaria/SoportePedidos.vue'),
          },
          {
            path: '/secretaria/historial/:id',
            name: 'HistorialSocio',
            component: () => import('@/views/secretaria/HistorialSocio.vue'),
          },
        ],
      },      
      {
        path: '/socio',
        name: 'Socio',
        meta: {socioAuth:true},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/dashboard',
        children: [
          {
            path: '/socio/pedidos',
            name: 'Pedidos',
            component: () => import('@/views/socio/Pedidos.vue'),
          },      
          {
            path: '/socio/historial',
            name: 'Historial',
            component: () => import('@/views/socio/Historial.vue'),
          },
          {
            path: '/socio/socio',
            name: 'Socio',
            component: () => import('@/views/socio/Socio.vue'),
          }    
        ],
      },
      {
        path: '/gerencia',
        name: 'Gerencia',
        meta: {gerenciaAuth:true},
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/dashboard',
        children: [
          {
            path: '/gerencia/pedidos',
            name: 'GerenciaPedidos',
            component: () => import('@/views/gerencia/Pedidos.vue'),
          },
          {
            path: '/gerencia/reportes',
            name: 'ReportesGerencia',
            component: () => import('@/views/gerencia/ReportesGerencia.vue'),
          }
        ],
      },   
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {

  const rutaProtegida = to.matched.some(record => record.meta.requireAuth);
  const rutaAdmin = to.matched.some(record => record.meta.adminAuth);  
  const rutaSecretaria = to.matched.some(record => record.meta.secretariaAuth);
  const rutaSocio = to.matched.some(record => record.meta.socioAuth);  
  const rutaGerencia = to.matched.some(record => record.meta.gerenciaAuth);  

  if(rutaProtegida && store.state.token === ''){  
    next({name: 'Login'});
  }else if(rutaAdmin){
    const user = decode(localStorage.getItem('token'));
    let rol = user.id_rol;
    if(Number(rol)===1){
      next();
    }else{
      next({name: 'Dashboard'});
    }    
  }else if(rutaSecretaria){
    const user = decode(localStorage.getItem('token'));
    let rol = user.id_rol;
    if(Number(rol===2 || rol===1)){
      next();
    }else{
      next({name: 'Dashboard'});
    } 
  }else if(rutaSocio){
    const user = decode(localStorage.getItem('token'));
    let rol = user.id_rol;
    if(Number(rol===3)){
      next();
    }else{
      next({name: 'Dashboard'});
    } 
  }else if(rutaGerencia){
    const user = decode(localStorage.getItem('token'));
    let rol = user.id_rol;
    if(Number(rol===4 || rol===1)){
      next();
    }else{
      next({name: 'Dashboard'});
    } 
  }else{
    next();  
  }


});



export default router
