<template>
  <CFooter>
    <div>
      <a href="https://aerondev.com" target="_blank">{{ author }}</a>
      <span class="ms-1"
        >&copy; {{ gestion }} .</span
      >
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank">{{ title }}</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
  created(){
    this.title = process.env.VUE_APP_TITLE;
    this.author=process.env.VUE_APP_AUTHOR;
    this.gestion = new Date().getFullYear();
  }
}
</script>
