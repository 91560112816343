export default [  
  {
    component: 'CNavItem',
    name: 'Inicio',
    to: '/dashboard',
    icon: 'cil-calculator'
  },
  {
    component: 'CNavTitle',
    name: 'Secretaria',
  },
  {
    component: 'CNavItem',
    name: 'Pedidos',
    to: '/secretaria/listaPedidos',
    icon: 'cil-calendar',
  },  
  {
    component: 'CNavItem',
    name: 'Socios',
    to: '/secretaria/socios',
    icon: 'cil-user',
  },
  {
    component: 'CNavItem',
    name: 'Reportes',
    to: '/secretaria/reportes',
    icon: 'cil-puzzle',
  }
]
